import auth from 'services/auth';

export function fromFormValue(val) {
  if (val == null || val === '') { return null; }
  const num = Number(val);
  if (isNaN(num)) { return null; }
  return num;
}

export function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function toMoney(val, fixedCurrency) {
  if (!val) { val = 0; }

  const currency = fixedCurrency || auth.user?.currency || '$';
  if (currency === '$' && Math.abs(val) < 1 && val !== 0) { return (val * 100).toFixed(0) + 'c'; }

  return (val < 0 ? '-' : '') + currency + Math.abs(val).toFixed(2);
}

export function calcTaxRate(taxRate) {
  if (taxRate == null) { return defaultTaxRate(); }
  return taxRate;
}

export function defaultTaxRate() {
  switch (auth.user.country) {
    case 'AU': return 0.1;
    case 'NZ': return 0.15;
    default: return 0;
  }
}

export function toFileSize(size) {
  let type = 'B';
  if (size > 9999) {
    size = size / 1024;
    type = 'KB';
  }
  if (size > 9999) {
    size = size / 1024;
    type = 'MB';
  }
  if (size > 9999) {
    size = size / 1024;
    type = 'GB';
  }
  return `${size.toFixed(0)} ${type}`;
}

export function toDurationStr(secs) {
  if (secs === null || isNaN(secs)) { return ''; }
  let type = 'sec';
  if (secs > 60) {
    secs = secs / 60;
    type = 'min';
  }
  if (secs > 60) {
    secs = secs / 60;
    type = 'hour';
  }
  const time = secs.toFixed(type === 'hour' ? 2 : 0);
  return `${time} ${type}${Number(time) === 1 ? '' : 's'}`;
}

export function currencyOnLeft(fixedCurrency) {
  return true;
}

export function fpFix(val) {
  if (!val && val != 0) { return null; } // eslint-disable-line eqeqeq
  const num = Number(parseFloat(val).toPrecision(15));
  return (num < 10e-13 && num > -10e-13) ? 0 : num;
}

export function uuidv4() {
  return ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function nthSuffix(value) {
  switch (value % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}