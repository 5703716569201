import { cloneElement, memo, forwardRef, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { combineRefs } from 'hooks';
import api from 'services/api';

// eslint-disable-next-line react/display-name
export default memo(forwardRef(function Image({ fallbackSrc, src, fallbackComponent, file, onLoad, onFileLoaded, cacheBust, ...other }, ref) {
  const [ hasFileError, setHasFileError ] = useState(false);
  const [ hasSrcError, setHasSrcError ] = useState(false);

  const [ fileSrc, fileFallback ] = useMemo(() => {
    let fileLink = null;
    let fileFallback = null;
    if (Array.isArray(file)) {
      const [ isFile, path, fallback ] = file;
      fileLink = isFile ? api.openFileUrl(path) : path;
      fileFallback = fallback;
    } else if (file) {
      fileLink = api.openFileUrl(file);
    }
    return [ fileLink || null, fileFallback || null ];
  }, [ file ]);

  const handleError = useCallback(() => {
    if (fileSrc && !hasFileError) {
      setHasFileError(true);
    } else {
      setHasSrcError(true);
    }
  }, [ fileSrc, hasFileError ]);

  const handleOnLoad = useCallback(e => {
    onLoad && onLoad(e);
    if (fileSrc && !hasFileError) {
      onFileLoaded && onFileLoaded(true);
    }
  }, [ onLoad, onFileLoaded, fileSrc, hasFileError ]);

  const compareSrc = fileSrc || src;
  useEffect(() => {
    setHasFileError(false);
    setHasSrcError(false);
    onFileLoaded && onFileLoaded(false);
  }, [ compareSrc, cacheBust, onFileLoaded ]);

  let finalSrc = !fileSrc || hasFileError ? src : fileSrc;
  if (hasSrcError || !finalSrc) {
    finalSrc = fallbackComponent ? null : fileFallback || fallbackSrc;
  }

  const refreshRef = useRef(null);
  useEffect(() => {
    if (!finalSrc || !cacheBust) { return; }
    refreshRef.current.src = finalSrc + (finalSrc.includes('?') ? '&' : '?') + new Date().getTime();
  }, [ cacheBust, finalSrc, handleError ]);

  const inputRef = combineRefs(ref, refreshRef);
  return !finalSrc && fallbackComponent
    ? (typeof fallbackComponent === 'string' ? fallbackComponent : cloneElement(fallbackComponent, { ref: inputRef })) // Passthrough ref to fallback component
    : <img ref={inputRef} src={finalSrc} onError={handleError} onLoad={handleOnLoad} {...other} />;
}));